import * as React from "react"
import { useContext } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout, { layoutContext } from "../components/layout"
import Form from "../components/form"
import Seo from "../components/seo"

import IconPhone from '../images/icon_phone.svg'
import icon_problem_1 from '../images/icon_problem_1.svg'
import icon_problem_2 from '../images/icon_problem_2.svg'
import icon_problem_3 from '../images/icon_problem_3.svg'
import icon_starts from '../images/icon_starts.svg'
import icon_moving from '../images/moving.svg'

import content from '../contents/home.yaml'

const IndexPage = () => {


  return (
    <Layout>
      <layoutContext.Consumer>
        {
          ({ setPopupStatus }) => (
            <>
              <Seo title="Long-Distance Moving, Auto Transport, and More" />

              <div className="l-banner l-banner-home lg:py-16">
                <div className="container lg:min-h-[600px] lg:grid grid-cols-2 gap-8 items-center py-12 lg:py-0">
                  <div class="text-center lg:text-left">
                    <h1 className="text-white text-h2 lg:text-h1 font-bold mb-4">Long-Distance Moving Without Lifting a Finger!</h1>
                    <h2 className="text-white text-h4 font-semibold mb-8">Start planning your move and enjoy your fresh start!</h2>
                    <input className="lg:hidden btn btn-primary px-4  mb-8" type="button" value="GET YOUR QUOTE NOW" onClick={e => setPopupStatus(true)} />

                    <a href="tel:+18664446343" className="m-auto lg:m-0 flex items-center gap-4 bg-[#F5F9EC] p-1 pr-8 rounded-full shadow-2xl w-fit">
                      <img src={IconPhone} />
                      <div className="font-bold">
                        <span className="text-sm4">Or give us a call!</span> <div className="text-sm1">(866) 688-8521</div>
                      </div>
                    </a>


                  </div>

                  <div class="hidden lg:block">
                    <Form />
                  </div>
                </div>
              </div> {/* End of l-banner */}


              <div className="container mt-12 lg:mt-[140px]">
                <div className="text-center lg:text-left lg:grid grid-cols-2 gap-8">
                  <div>
                    <h3 className="text-h3 lg:text-h2 font-bold">We move more than just boxes…</h3>
                    <p className="text-p mt-7">Your belongings are not just inanimate objects, every little thing carries memories and we are here to help you move them with care and compassion. No matter what kind of move you're looking for we can help you cover it—and not just with boxes and tape. </p>
                    <input className="mt-7 btn btn-primary px-4  mb-8" type="button" value="GET YOUR QUOTE NOW" onClick={e => setPopupStatus(true)} />
                  </div>

                  <div>
                    {/* <StaticImage
                      src="../images/moving.svg"
                      layout="fullWidth"
                    /> */}

                    <img src={icon_moving} className="w-full" />
                  </div>
                </div>
              </div>  {/* End of section */}



              <div className="container mt-16 lg:mt-[140px]">
                <h3 className="text-h3 lg:text-h2 mb-8 lg:mb-[100px] font-bold text-center">We’re Here to Help</h3>
                <div className="lg:grid grid-cols-3">
                  <div className="hidden lg:block max-w-[214px]"><h3 className="text-sm1 font-semibold">Your Full Service Moving Broker</h3></div>
                  <div className="m-pinBox">
                    <p>Leave it all to us. We handle everything from packing to transporting, and storage. We provide full breakdown and disassembly of furniture even delicate crystals and pianos.</p>
                  </div>
                  <div class="row-span-3 hidden lg:block ">
                    <StaticImage
                      src="../images/help_1.jpg"
                      layout="fullWidth"
                      class="ml-8"
                    />
                  </div>
                  <div className="hidden lg:block"><h3 className="text-sm1 font-semibold">Your Move Made Easy</h3></div>
                  <div className="m-pinBox">
                    <p>We are a full-service moving broker that moves your entire home with the utmost care. We take care of everything - all you have to worry about is settling into your new home.</p>
                  </div>
                  <div className="hidden lg:block"><h3 className="text-sm1 font-semibold">Your Things Kept Safe</h3></div>
                  <div className="m-pinBox border-0">
                    <p>Get 30 days of free storage, pickup, and redelivery! Just let us know where you are, where you're going, and what you need to be moved or stored. We'll take care of the rest!</p>
                  </div>
                </div>
              </div>  {/* End of section */}


              <div className="container lg:rounded-3xl bg-[#F4F4EE] text-center lg:mt-[100px] pt-[50px] lg:pt-[90px] pb-[63px]">

                <div className="relative grid lg:grid-cols-3 gap-y-16 max-w-[800px] m-auto mb-10">
                  <div className="hidden lg:absolute z-0 top-[45%] left-[15%] w-2/3 border-2 border-[#C5C5C5]"></div>
                  <div className="lg:hidden absolute z-0 top-[10%] left-[50%] w-[2px] h-2/3 border-2 border-[#C5C5C5]"></div>

                  <div className="relative z-1 bg-[#F4F4EE]">
                    <img className="inline-block" src={icon_problem_1} />
                    <p className="text-h3 font-semibold">You're Moving</p>
                  </div>
                  <div className="relative z-1 bg-[#F4F4EE]">
                    <img className="inline-block" src={icon_problem_2} />
                    <p className="text-h3 font-semibold">You’re Packing </p>
                  </div>
                  <div className="relative z-1 bg-[#F4F4EE]">
                    <img className="inline-block" src={icon_problem_3} />
                    <p className="text-h3 font-semibold">You’re Panicking</p>
                  </div>
                </div>

                <p className="max-w-[600px] m-auto">
                  Our job is to save you from the horrors of moving because we know that if we were in your shoes, we'd rather be doing anything else.
                </p>

                <input className="mt-14 btn btn-primary px-4" type="button" value="GET YOUR QUOTE NOW" onClick={e => setPopupStatus(true)} />




              </div> {/* End of section */}


              <div className="container mt-16 lg:mt-[120px] text-center">
                <div className="m-auto max-w-[750px]">
                  <h3 className="text-h3 lg:text-h2 font-bold mb-8">Don't Worry, Though—We've Got This.</h3>
                  <p className="mb-[70px]">All you have to do is get settled in your new place!<br /> Our full range of services guarantees that anything from the packing to the actual relocation itself can be done by our team of experts.</p>

                </div>
                <StaticImage
                  src="../images/help_2.jpg"
                  layout="fullWidth"
                  class="ml-8"
                />
                <input className="hidden lg:inline-block mt-14 btn btn-primary px-4" type="button" value="GET YOUR QUOTE NOW" onClick={e => setPopupStatus(true)} />


              </div>{/* End of section */}


              <div className="container mt-16 lg:mt-[150px]">
                <div className="m-auto max-w-[750px] text-center">
                  <h3 className="text-h3 lg:text-h2 font-bold mb-8">We're One of The Most Trusted Names in Long-Distance Moving…</h3>
                  <p className="mb-[70px]">…And we've got the 5-star ratings to show for it.</p>
                </div>

                <div className="flex gap-x-7 overflow-x-scroll pb-4">

                  {
                    content.testimonials.map(testimonial =>
                      <div className="m-review min-w-[350px] lg:min-w-[490px] border border-lightgray p-6 rounded-lg">
                        <img src={icon_starts} className="mb-2" />
                        <p className="text-black mb-2 font-medium">{testimonial.title}</p>
                        <p className="text-darkgray mb-7 text-micro lg:text-p">
                          {testimonial.comments}
                        </p>

                        <div class="flex justify-between items-center">
                          <p>
                            <span class="font-bold text-sm3 mb-1">
                              - {testimonial.name}
                            </span>

                          </p>
                          <div>
                            <StaticImage src="../images/source.png" />
                          </div>
                        </div>
                      </div>
                    )
                  }


                </div>


              </div>{/* End of section */}




              <div className="container mt-16 lg:mt-[150px] bg-green py-16 lg:rounded-lg">
                <div className="m-auto max-w-[750px] text-center">
                  <h3 className="text-h3 text-white lg:text-h2 font-bold mb-4">We Make Moving Easier Than Ever</h3>
                  <p className="text-white">Get an instant estimate of how much it will cost to move with us.</p>
                  <input className="lg:inline-block mt-8 btn btn-white px-4" type="button" value="GET YOUR QUOTE NOW" onClick={e => setPopupStatus(true)} />

                </div>
              </div>




            </>
          )
        }
      </layoutContext.Consumer>





    </Layout>
  )
}


export default IndexPage
